import {
  faAngleLeft,
  faAngleRight,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import Button from '../components/formComponents/Button';
import ModalLayout from './ModalLayout';
import Textarea from './formComponents/Textarea';
import CheckBox from './CheckBox';
import axiosInstance from '../axiosInstance';
import { useForm } from 'react-hook-form';
import { useEffect, useState, useCallback } from 'react';
import TempPanelCard from './TempPanelCard';
import ConfirmModal from './ConfirmModal';
import { toast } from 'react-toastify';
import Loading from './Loading';
const ModalImage = ({
  item,
  show,
  images,
  setImage,
  handleImageClick,
  imageSelect,
  handleFixes,
  setShow,
  loading,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: { comment: '' } });

  const [isFixed, setIsFixed] = useState(false);
  const [selected, setSelected] = useState(false);
  const [comments, setComments] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleOpen = useCallback(() => {
    setIsFixed((prev) => !prev);
  }, []);

  const handleFixed = useCallback(() => {
    handleFixes(isFixed, item.id);
    setIsFixed((prev) => !prev);
    setShow(false);
  }, [handleFixes, isFixed, item.id, setShow]);

  const fetchComments = useCallback(async () => {
    if (!item.id) {
      setComments(null);
      return;
    }
    try {
      const response = await axiosInstance.getData(
        `/comments/image/${item.id}`,
      );
      setComments(response?.comments);
    } catch (error) {
      toast.error('Error fetching comments');
    }
  }, [item.id]);

  useEffect(() => {
    fetchComments();
  }, [fetchComments]);

  useEffect(() => {
    setSelected(
      (item.isInspected && !item.isHealthy) || imageSelect === item.id,
    );
  }, [item, imageSelect]);

  const handleNext = useCallback(() => {
    const nextIndex = images.indexOf(item) + 1;
    if (nextIndex < images.length) {
      setIsLoading(true);
      setImage(images[nextIndex]);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [images, item, setImage]);
  const handlePrevious = useCallback(() => {
    const previousIndex = images.indexOf(item) - 1;
    if (previousIndex >= 0) {
      setIsLoading(true);
      setImage(images[previousIndex]);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [images, item, setImage]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        handleNext();
      } else if (event.key === 'ArrowLeft') {
        handlePrevious();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleNext, handlePrevious]);

  const onSubmit = async (data) => {
    reset();
    const comment = {
      projectId: item.id,
      comment: data.comment,
    };
    try {
      const res = await axiosInstance.postData(`comments`, comment);
      setComments((prevData) => [res.data, ...prevData]);
      toast.success('Comment added successfully');
    } catch (err) {
      toast.error('Error adding comment');
    }
  };
  const fileName = item.image && item?.image.split('/').pop();

  return (
    <ModalLayout
      open={show}
      handleClose={handleImageClick}
      className={` ${
        selected
          ? ' h-[98%] md:w-[90%] xl:w-[60%]'
          : 'h-[70%] md:w-[60%] xl:w-[40%] 2xl:h-[90%]'
      }  w-[98%]  md:h-[90%]  pb-5 p-3 md:p-6`}
    >
      {isLoading && <Loading fullscreen />}

      <ConfirmModal
        title="Are you sure you want to change the image status to 'Healthy'?"
        handleClose={handleOpen}
        open={isFixed}
        onSubmit={handleFixed}
        buttonText="Yes"
        classNameButton="!bg-green-500 hover:!bg-green-400"
      />
      <div
        className="absolute right-0 z-30 md:-right-6 w-10 h-10 cursor-pointer backdrop-blur-xl bg-white/20 hover:bg-white/30 rounded-full flex justify-center items-center"
        onClick={handleNext}
      >
        <FontAwesomeIcon icon={faAngleRight} className="text-xl" />
      </div>
      <div
        className="absolute left-0 z-30 md:-left-6 w-10 h-10 cursor-pointer backdrop-blur-xl bg-white/20 hover:bg-white/30 rounded-full flex justify-center items-center"
        onClick={handlePrevious}
      >
        <FontAwesomeIcon icon={faAngleLeft} className="text-xl " />
      </div>

      <div className="w-full h-full flex justify-start flex-col pt-6 md:flex-row max-h-[700px] md:max-h-full">
        <Typography
          className="absolute w-3/4 top-3 left-0  flex justify-center items-center cursor-pointer"
          // onClick={handleImageClick}
          component="span"
          variant="body2"
        >
          <div className="w-full pl-6 text-xl">{fileName}</div>

          {/* <FontAwesomeIcon icon={faXmark} className="text-xl" /> */}
        </Typography>
        <div
          className={`${
            selected ? 'w-full md:w-2/3' : 'w-full'
          } h-[63%] md:h-[100%]`}
        >
          <div className="w-full h-3/5   ">
            <img
              src={item?.image}
              alt={item?.id}
              className="rounded-xl object-fill h-full w-full pb-2"
            />
          </div>

          <form
            className="w-full flex items-top"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="w-full flex flex-col">
              <Textarea
                className="w-52 md:w-full !h-11 text-sm md:text-base"
                id="comment"
                placeholder="Enter your comment here"
                control={control}
                options={{ required: true }}
                errors={errors}
              />
            </div>
            <div className="rounded-lg flex items-top py-1.5 h-full justify-end w-[40%]">
              <Button
                type="submit"
                text="Save"
                className="rounded-lg px-5 items-center h-11 w-24 md:w-32"
                disabled={loading}
              />
            </div>
          </form>
          <div
            className={` ${
              selected ? ' h-[30%]' : 'h-[40%]'
            } w-full md:h-[30%] lg:h-[30%] flex flex-1 flex-col rounded-lg`}
          >
            {comments?.length >= 1 && (
              <p className="text-gray-400  px-2">Comments:</p>
            )}
            <div className="overflow-auto space-y-2">
              {comments?.map((comment, index) => (
                <div
                  key={index}
                  className="w-full relative pb-4 text-sm md:text-base text-gray-400 bg-[#170630] border border-solid border-[#374151] px-3 py-2 rounded-lg"
                >
                  {comment.comment}
                  <p className="text-gray-400 text-xs absolute bottom-1 right-4">
                    {new Date(comment.createdAt).toLocaleDateString()}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {selected && (
          <div className="w-full pt-2 md:w-1/3 h-full pl-2">
            <div className="w-full flex items-center space-x-2 py-4 sm:py-2 md:p-2 pr-2 rounded-lg">
              <CheckBox
                checked={isFixed}
                onChange={handleOpen}
                label="Check this box to change the status of the image to Healthy."
              />
            </div>
            <div className="w-full overflow-y-auto max-h-52 md:max-h-[90%] lg:p-2">
              {item?.panelInformation?.map((panelItem, index) => (
                <TempPanelCard key={index} item={panelItem} />
              ))}
            </div>
          </div>
        )}
      </div>
    </ModalLayout>
  );
};

export default ModalImage;
