export default function ArrowButton({
  fillColor = 'url(#paint0_linear_108_151)',
  width = '18',
  height = '22',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_108_151)">
        <path
          d="M10.3378 5.70935C10.4897 5.86113 10.5751 6.06698 10.5753 6.28169C10.5755 6.49641 10.4905 6.70243 10.3389 6.8545L7.28675 9.91426C7.1349 10.0664 6.92883 10.152 6.71387 10.1522C6.49891 10.1524 6.29267 10.0672 6.14053 9.91537C5.98838 9.76352 5.90279 9.55745 5.90258 9.34249C5.90237 9.12753 5.98756 8.92129 6.13941 8.76915L7.81012 7.09463L1.57935 7.10071C1.36445 7.10092 1.15828 7.01575 1.00618 6.86395C0.854087 6.71215 0.768521 6.50614 0.768311 6.29125C0.768102 6.07636 0.853266 5.87019 1.00507 5.71809C1.15687 5.56599 1.36288 5.48043 1.57777 5.48022L7.80854 5.47415L6.13457 3.80343C6.05923 3.72824 5.99944 3.63895 5.95862 3.54066C5.91779 3.44236 5.89672 3.33699 5.89662 3.23055C5.89651 3.12411 5.91738 3.0187 5.95801 2.92033C5.99865 2.82195 6.05826 2.73254 6.13345 2.65721C6.20864 2.58187 6.29793 2.52208 6.39623 2.48126C6.49452 2.44043 6.5999 2.41936 6.70633 2.41926C6.81277 2.41915 6.91818 2.44002 7.01656 2.48065C7.11493 2.52129 7.20434 2.5809 7.27968 2.65609L10.3378 5.70935Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_108_151"
          x1="10.5753"
          y1="6.28196"
          x2="0.768311"
          y2="6.29152"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6B00" />
          <stop offset="1" stopColor="#7601F9" />
        </linearGradient>
        <clipPath id="clip0_108_151">
          <rect
            width="11.3415"
            height="11.3415"
            fill="white"
            transform="matrix(-0.000974776 -1 -1 0.000974807 12.1143 11.957)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
