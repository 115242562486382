export default function Delete({
  width = '30px',
  height = '30px',
  fill = '#000000',
  className = '',
  onClick,
}) {
  return (
    <svg
      className="svg-icon"
      style={{
        verticalAlign: 'middle',
        overflow: 'hidden',
      }}
      width={width}
      height={height}
      fill={fill}
      className={className}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path d="M938.667 202.667h-160v-32A117.547 117.547 0 0 0 661.333 53.333H362.667a117.547 117.547 0 0 0-117.334 117.334v32h-160a32 32 0 0 0 0 64h853.334a32 32 0 0 0 0-64z m-629.334-32a53.333 53.333 0 0 1 53.334-53.334h298.666a53.333 53.333 0 0 1 53.334 53.334v32H309.333z m128 618.666V384a32 32 0 0 0-64 0v405.333a32 32 0 0 0 64 0z m213.334 0V384a32 32 0 0 0-64 0v405.333a32 32 0 0 0 64 0z" />
      <path d="M832 330.667a32 32 0 0 0-32 32v490.666a53.333 53.333 0 0 1-53.333 53.334H277.333A53.333 53.333 0 0 1 224 853.333V362.667a32 32 0 0 0-64 0v490.666a117.547 117.547 0 0 0 117.333 117.334h469.334A117.547 117.547 0 0 0 864 853.333V362.667a32 32 0 0 0-32-32z" />
    </svg>
  );
}
