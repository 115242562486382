export default function LogOut({ fillColor, width = '37', height = '37' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: 'pointer' }}
    >
      <path
        d="M19.6053 5.06055C19.9151 5.06055 20.2121 5.18359 20.4311 5.4026C20.6502 5.62161 20.7732 5.91866 20.7732 6.22839C20.7732 6.53813 20.6502 6.83517 20.4311 7.05419C20.2121 7.2732 19.9151 7.39624 19.6053 7.39624C16.6113 7.39624 13.7398 8.58563 11.6227 10.7028C9.50556 12.8199 8.31616 15.6913 8.31616 18.6854C8.31616 21.6795 9.50556 24.551 11.6227 26.6681C13.7398 28.7852 16.6113 29.9746 19.6053 29.9746C19.9151 29.9746 20.2121 30.0977 20.4311 30.3167C20.6502 30.5357 20.7732 30.8327 20.7732 31.1425C20.7732 31.4522 20.6502 31.7492 20.4311 31.9682C20.2121 32.1873 19.9151 32.3103 19.6053 32.3103C15.9918 32.3103 12.5263 30.8748 9.9711 28.3197C7.41594 25.7645 5.98047 22.299 5.98047 18.6854C5.98047 15.0719 7.41594 11.6063 9.9711 9.05118C12.5263 6.49602 15.9918 5.06055 19.6053 5.06055Z"
        fill={fillColor}
        fillOpacity="0.64"
      />
      <path
        d="M26.5657 14.8409C26.3594 14.6196 26.2471 14.3268 26.2525 14.0242C26.2578 13.7216 26.3804 13.433 26.5943 13.219C26.8083 13.005 27.097 12.8825 27.3995 12.8771C27.7021 12.8718 27.9949 12.9841 28.2163 13.1904L32.8877 17.8618C33.1064 18.0807 33.2292 18.3776 33.2292 18.6871C33.2292 18.9965 33.1064 19.2934 32.8877 19.5123L28.2163 24.1837C28.1094 24.2985 27.9804 24.3905 27.8372 24.4543C27.6939 24.5182 27.5393 24.5525 27.3825 24.5552C27.2257 24.558 27.0699 24.5292 26.9245 24.4704C26.7791 24.4117 26.647 24.3243 26.5361 24.2134C26.4252 24.1025 26.3377 23.9704 26.279 23.825C26.2203 23.6795 26.1914 23.5238 26.1942 23.367C26.197 23.2102 26.2313 23.0555 26.2951 22.9123C26.3589 22.769 26.451 22.6401 26.5657 22.5332L29.244 19.8549H16.4911C16.1814 19.8549 15.8843 19.7319 15.6653 19.5128C15.4463 19.2938 15.3232 18.9968 15.3232 18.6871C15.3232 18.3773 15.4463 18.0803 15.6653 17.8613C15.8843 17.6423 16.1814 17.5192 16.4911 17.5192H29.244L26.5657 14.8409Z"
        fill={fillColor}
        fillOpacity="0.64"
      />
    </svg>
  );
}
