const TempPanelCard = ({ item }) => {
  return (
    <div className="grid grid-cols-1 bg-background shadow-md rounded-lg p-2 mb-2 transition-transform transform hover:scale-105">
      <div className="flex items-center justify-between mb-1">
        <span className="font-bold ">Anomaly:</span>
        <span className="text-blue-600">{item.PanelNumber}</span>
      </div>
      <div className="flex items-center justify-between mb-1">
        <span className="font-bold ">Highest Temp:</span>
        <span className="text-red-500">{item.HighestTemp}°C</span>
      </div>
      <div className="flex items-center justify-between mb-1">
        <span className="font-bold ">Avg Temp:</span>
        <span className="text-green-500">{item.AvgTemp}°C</span>
      </div>
      <div className="flex items-center justify-between">
        <span className="font-bold ">Delta:</span>
        <span className="text-purple-500">{item.Delta}°C</span>
      </div>
    </div>
  );
};

export default TempPanelCard;
