import React from 'react';
import Loading from './Loading';
import Upload from '../assets/icons/Upload';

function FileUpload({ file, handleFileChange, register, loading, errors }) {
  const errorShowMessage = (error) => <p className="text-red-600">{error}</p>;

  return (
    <div className="flex w-full justify-center items-center flex-col  pt-10">
      <label
        htmlFor="file"
        className="flex flex-col items-center justify-center w-full h-48 mb-6 border-2 border-gray-400 hover:border-gray-200 border-dashed rounded-lg cursor-pointer bg-buttonColor  hover:bg-gray-700"
      >
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          {loading ? (
            <Loading />
          ) : (
            <>
              <Upload className={'h-8 w-8 text-gray-500'} />

              <p className="mb-2 text-center text-sm text-gray-400">
                <span className="font-semibold">Click to upload</span> or drag
                and drop
              </p>
              <p className="text-xs text-gray-400">
                Zip, Rar, Jpg or Png (MAX.15GB)
              </p>
              {file?.length > 0 && (
                <p className="text-lg text-gray-400 mt-2">
                  {file?.length === 1
                    ? file[0]?.name
                    : file?.length + ' files selected'}
                </p>
              )}
            </>
          )}
        </div>
        <input
          id="file"
          type="file"
          accept=".zip, .jpg, .png, .JPG"
          className="hidden"
          multiple
          {...register('file', { required: 'File is required' })}
          onChange={handleFileChange}
        />
      </label>
      {errors['file'] && errorShowMessage(errors['file'].message)}
    </div>
  );
}

export default FileUpload;
