import axios from 'axios';

let token = localStorage.getItem('token');
let axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL + 'api',
  headers: {
    'Content-type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);

class AxiosInstance {
  async login(postData) {
    try {
      return await axiosInstance
        .post('/auth/login', postData)
        .then((response) => {
          if (response.data.data.token) {
            token = response.data.data.token;
            localStorage.setItem('token', token);
          }
          return response.data;
        });
    } catch (error) {
      throw error;
    }
  }
  async contactUs(postData, headers = {}) {
    try {
      return await axiosInstance
        .post('/contactus', postData, headers)
        .then((response) => {
          return response.data;
        });
    } catch (error) {
      return error;
    }
  }

  async getData(url) {
    try {
      return await axiosInstance.get(url).then((response) => {
        return response.data;
      });
    } catch (error) {
      return error;
    }
  }
  async postData(url, postData, headers = {}) {
    try {
      return await axiosInstance
        .post(url, postData, headers)
        .then((response) => {
          return response.data;
        });
    } catch (error) {
      return error;
    }
  }

  async updateData(url, postData) {
    try {
      return await axiosInstance.put(url, postData).then((response) => {
        return response.data;
      });
    } catch (error) {
      return error;
    }
  }

  async deleteData(url) {
    try {
      return await axiosInstance.delete(url).then((response) => {
        return response.data;
      });
    } catch (error) {
      return error;
    }
  }

  async generateUnhealthyImagesPdf(projectId) {
    try {
      const response = await axiosInstance.getData(
        `/pdf/unhealthy-images/${projectId}`,
        {
          responseType: 'blob',
        },
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }

  logout() {
    localStorage.removeItem('token');
  }
}
const initializeAxios = new AxiosInstance();

export default initializeAxios;
