import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { faDiagramProject } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInstance';
import { setIsAuthorized } from '../Redux/features/authorization/authorizationSlice';
import { useDispatch } from 'react-redux';
import Logo from '../assets/icons/Logo';
import Vector from '../assets/icons/SidebarVector';
import Profile from '../assets/icons/Profile';
import LogOut from '../assets/icons/LogOut';

const drawerWidth = '100%';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
}));

const SideBar = ({ setNav }) => {
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogOut = () => {
    axiosInstance.logout();
    navigate('/login');
    dispatch(setIsAuthorized(false));
  };

  useEffect(() => {
    const path = location.pathname;
    setNav(false);
    setCurrentTab(path.split('/')[2]);
  }, [location]);

  return (
    <div className=" z-[100] md:block h-full w-full md:w-[253px] 3xl:w-[20%] fixed top-0 min-w-[230px]">
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Drawer variant="permanent" open={true} onClick={() => setNav(false)}>
          <DrawerHeader sx={{ padding: '25px' }}>
            <Logo fillColor="transparent" width="100%" />
          </DrawerHeader>
          <Divider />
          <List className="h-full">
            <div className="absolute bottom-5 right-5 z-[2000] overflow-hidden">
              <Vector fillColor="transparent" />
            </div>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <Link to={'admin/projects'}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: 'initial',
                    py: 2,
                    px: 3,
                    ml: 1,
                    backgroundColor:
                      (currentTab === 'projects' || currentTab === 'project') &&
                      '#190B33',
                    borderRadius: '32px 0 0 32px',
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: 3,
                      justifyContent: 'center',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faDiagramProject}
                      className={`w-9 h-9 ${
                        currentTab === 'projects' || currentTab === 'project'
                          ? 'text-[#529FF9]'
                          : 'text-[#d9d9d9aa]'
                      }`}
                    />
                  </ListItemIcon>
                  <h1
                    className={`w-9 text-lg ${
                      currentTab === 'projects' || currentTab === 'project'
                        ? 'text-activeColorSidebar'
                        : 'text-colorSidebar'
                    }`}
                  >
                    Projects
                  </h1>
                </ListItemButton>
              </Link>
            </ListItem>

            <Link to={'admin/profile'}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: 'initial',
                  py: 2,
                  px: 3,
                  ml: 1,
                  backgroundColor: currentTab === 'profile' && '#190B33',
                  borderRadius: '32px 0 0 32px',
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 3,
                    justifyContent: 'center',
                  }}
                >
                  <Profile
                    fillColor={currentTab === 'profile' ? '#529FF9' : '#C5C5CF'}
                  />
                </ListItemIcon>
                <h1
                  className={`w-9 text-lg ${
                    currentTab === 'profile'
                      ? 'text-activeColorSidebar'
                      : 'text-colorSidebar'
                  }`}
                >
                  Profile
                </h1>
              </ListItemButton>
            </Link>

            <ListItem
              disablePadding
              sx={{ display: 'block', position: 'absolute', bottom: 0 }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: 'initial',
                  py: 2,
                  px: 3,
                  ml: 1,
                  borderRadius: '32px 0 0 32px',
                  zIndex: '10000 !important',
                }}
                onClick={() => handleLogOut()}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 3,
                    justifyContent: 'center',
                  }}
                >
                  <LogOut fillColor="#C5C5CF" />
                </ListItemIcon>
                <h1 className="w-9 text-lg z-[10000] text-colorSidebar">
                  Log out
                </h1>
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      </Box>
    </div>
  );
};

export default SideBar;
