import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ArrowButton from '../assets/icons/ArrowButton';
import ImageColorCategory from './ImageColorCategory';
import ModalImage from './ModalImage';

function ImageInfiniteScroll({
  fetchData,
  items,
  hasMore,
  setImage,
  error,
  imageSelect,
  imageRefs,
  showImage,
  loading,
  handleImageClick,
  handleFixes,
  image,
}) {
  return (
    <>
      <InfiniteScroll
        dataLength={items.length}
        next={fetchData}
        hasMore={hasMore}
        loader={
          <div className="flex justify-center animate-[bounce_1.5s_ease-in-out_infinite] py-8">
            <p className="text-xl flex justify-center items-center  rotate-90 w-fit rounded-full">
              <ArrowButton />
            </p>
          </div>
        }
        endMessage={
          <div className="flex justify-center py-8">
            <p className="text-xl flex justify-center items-center py-4 px-4 lg:px-32 border border-purple text-white w-fit rounded-xl">
              No more pictures to load!
            </p>
          </div>
        }
      >
        <div className="grid grid-cols-2  sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7 p-2 gap-4">
          {items.map((item, index) => (
            <div
              ref={(el) => (imageRefs.current[index] = el)}
              className={`relative border-2 rounded-lg transform transition-all duration-300 ${
                imageSelect === item.id
                  ? 'border-red-500 animate-pulse scale-110'
                  : 'border-transparent'
              }`}
              key={index}
            >
              <ImageColorCategory item={item} />
              <img
                onClick={() => handleImageClick(item)}
                className="h-20 xl:h-28 w-full bg-cover rounded-lg"
                src={item.image}
                alt={item.id}
              />
            </div>
          ))}
        </div>
      </InfiniteScroll>
      <ModalImage
        item={image}
        images={items}
        setImage={setImage}
        isLoading={loading}
        show={showImage}
        setShow={handleImageClick}
        handleFixes={handleFixes}
        handleImageClick={handleImageClick}
      />
      {error && <p>Error: {error.message}</p>}
    </>
  );
}

export default ImageInfiniteScroll;
