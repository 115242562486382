export default function Profile({ fillColor, width = '25', height = '31' }) {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_108_958)">
        <path
          d="M17.8065 17.8779C20.1976 17.8779 22.2682 17.0203 23.96 15.3283C25.6518 13.6365 26.5094 11.5665 26.5094 9.17502C26.5094 6.78441 25.6518 4.71412 23.9597 3.02178C22.2677 1.33027 20.1974 0.472656 17.8065 0.472656C15.415 0.472656 13.345 1.33027 11.6532 3.02206C9.96141 4.71385 9.10352 6.78413 9.10352 9.17502C9.10352 11.5665 9.96141 13.6367 11.6535 15.3285C13.3455 17.02 15.4158 17.8779 17.8065 17.8779Z"
          fill={fillColor}
          fillOpacity="0.64"
        />
        <path
          d="M33.0366 28.256C32.9878 27.552 32.8891 26.784 32.7438 25.9729C32.5972 25.1559 32.4083 24.3834 32.1823 23.6774C31.9488 22.9477 31.6312 22.2271 31.2387 21.5366C30.8312 20.8198 30.3527 20.1957 29.8156 19.6821C29.2541 19.1449 28.5666 18.7129 27.7715 18.3978C26.9793 18.0844 26.1012 17.9256 25.162 17.9256C24.7932 17.9256 24.4365 18.0769 23.7476 18.5254C23.3236 18.8019 22.8277 19.1217 22.2741 19.4754C21.8008 19.777 21.1596 20.0595 20.3676 20.3154C19.5949 20.5654 18.8103 20.6922 18.0359 20.6922C17.2616 20.6922 16.4773 20.5654 15.7037 20.3154C14.9126 20.0598 14.2714 19.7772 13.7986 19.4757C13.2503 19.1253 12.7541 18.8055 12.3237 18.5252C11.6357 18.0766 11.2787 17.9253 10.9098 17.9253C9.97033 17.9253 9.0926 18.0844 8.30059 18.3981C7.50611 18.7126 6.81831 19.1446 6.25621 19.6824C5.71948 20.1963 5.24064 20.8201 4.83375 21.5366C4.44147 22.2271 4.12389 22.9475 3.89012 23.6777C3.66435 24.3837 3.47551 25.1559 3.32886 25.9729C3.18358 26.7829 3.08489 27.5512 3.03609 28.2569C2.98813 28.9482 2.96387 29.6658 2.96387 30.3906C2.96387 32.2767 3.56345 33.8036 4.74581 34.9297C5.91355 36.041 7.45869 36.6047 9.33767 36.6047H26.7358C28.6148 36.6047 30.1594 36.0412 31.3274 34.9297C32.5101 33.8045 33.1096 32.2772 33.1096 30.3903C33.1094 29.6622 33.0848 28.9441 33.0366 28.256Z"
          fill={fillColor}
          fillOpacity="0.64"
        />
      </g>
      <defs>
        <clipPath id="clip0_108_958">
          <rect
            width="36.1328"
            height="36.1328"
            fill={fillColor}
            transform="translate(0 0.46875)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
