import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import SideBar from './SideBar';

const DashboardLayout = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
    <div className="flex relative flex-col md:flex-row text-white  min-h-screen w-full  bg-[#190B33]">
      <div
        className="space-y-1 md:hidden flex p-4 flex-col items-end cursor-pointer"
        onClick={() => setIsNavOpen((prev) => !prev)}
      >
        <span className="block h-0.5 w-5  bg-gray-600"></span>
        <span className="block h-0.5 w-5  bg-gray-600"></span>
        <span className="block h-0.5 w-5  bg-gray-600"></span>
      </div>
      <div
        className={` ${
          isNavOpen ? '' : 'hidden'
        }    md:block md:w-[253px]  3xl:w-[20%] `}
      >
        <SideBar setNav={setIsNavOpen} />
      </div>

      <div className=" h-full  w-full md:pl-20 md:pt-10">
        <Outlet />
      </div>
    </div>
  );
};

export default DashboardLayout;
