import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../axiosInstance';
import Button from '../../../components/formComponents/Button';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Tooltip from '../../../assets/icons/Tooltip';
import ModalBox from '../../../components/ModalBox';
import Title from '../../../components/Title';
import moment from 'moment';
import Delete from '../../../assets/icons/Delete';
import ConfirmModal from '../../../components/ConfirmModal';

const SkeletonCard = () => (
  <div className="animate-pulse">
    <div className="flex flex-col justify-between items-center rounded-xl bg-gray-300 h-40 w-full p-4">
      <div className="w-3/4 h-4 bg-gray-400 rounded"></div>
      <div className="w-full h-4 bg-gray-400 rounded mt-auto"></div>
    </div>
  </div>
);

export const inputs = [
  {
    name: 'Name',
    id: 'name',
    type: 'text',
    placeholder: 'Name',
    options: {
      required: 'Name is required',
      minLength: {
        value: 3,
        message: 'Minimum 3 characters required',
      },
    },
  },
  {
    name: 'Description',
    id: 'description',
    type: 'textarea',
    placeholder: 'Description',
    options: {
      minLength: {
        value: 5,
        message: 'Minimum 5 characters required',
      },
    },
  },
  {
    name: 'Location',
    id: 'location',
    type: 'text',
    placeholder: 'Google map location link',
    options: {
      required: 'Location is required',
      minLength: {
        value: 3,
        message: 'Minimum 3 characters required',
      },
      pattern: {
        value: /^https:\/\/www.google.com\/maps*/,
        message: 'Only Google Maps location links are allowed',
      },
    },
    title:
      'Copy link of google map location from google maps website and paste it here.',
    svg: <Tooltip />,
  },
  {
    name: 'Megawatt',
    id: 'megawatt',
    type: 'number',
    steps: '0.1',
    min: 1,
    placeholder: 'Megawatt',
    options: {},
  },
];

const Main = () => {
  // const [allProjects, setAllProjects] = useState([]); // Store all projects
  const [filteredProjects, setFilteredProjects] = useState([]); // Store filtered projects
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);
  const [sortOrder, setSortOrder] = useState('asc');
  // const [showFilters, setShowFilters] = useState(false);
  // const [filters, setFilters] = useState({
  //   status: '',
  //   startDate: '',
  //   endDate: '',
  //   minMegawatt: '',
  //   maxMegawatt: '',
  // });

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const [openDelete, setOpenDelete] = useState(false);

  const handleCloseDelete = (id) => {
    if (id) {
      setId(id);
    }
    reset();
    setOpenDelete(!openDelete);
  };

  const deleteProject = () => {
    axiosInstance
      .deleteData(`projects/${id}`)
      .then((res) => {
        toast.success('Project deleted successfully');
        setUpdate(!update);
      })
      .catch((err) => {
        toast.error('Error deleting project');
      });

    handleCloseDelete();
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const locationData = await generateLocationFromLink(data.location);
    data.latitude = locationData.latitude;
    data.longitude = locationData.longitude;
    data.location = locationData.location;
    data.megawatt = parseFloat(data.megawatt);
    await postData(data);
    setLoading(false);
  };

  const postData = async (data) => {
    try {
      const res = await axiosInstance.postData('/projects', data);
      if (res.project) {
        handleClose();
        toast.success('Project was added successfully!');
        // setAllProjects((prevProjects) => [res.project, ...prevProjects]);
        setFilteredProjects((prevProjects) => [res.project, ...prevProjects]);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const generateLocationFromLink = async (link) => {
    const linkArray = link.split('/');
    let latitude, longitude;

    if (linkArray[4].includes('@')) {
      [latitude, longitude] = linkArray[4].split('@')[1].split(',');
    } else {
      [latitude, longitude] = linkArray[6].split('@')[1].split(',');
    }

    const url = `https://us1.locationiq.com/v1/reverse?key=pk.bc243611b6f2051ba56b510e504a1fcd&lat=${latitude}&lon=${longitude}&format=json`;

    const response = await fetch(url);
    const data = await response.json();
    return {
      location: data.display_name || 'Unknown location',
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
    };
  };

  const sortProjects = () => {
    const sorted = [...filteredProjects].sort((a, b) => {
      const dateA = new Date(a.createdat).getTime();
      const dateB = new Date(b.createdat).getTime();
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });
    setFilteredProjects(sorted);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axiosInstance.getData('/projects/me');
        if (isMounted) {
          // setAllProjects(res);
          setFilteredProjects(res);
          setLoading(false);
        }
      } catch (err) {
        if (isMounted) {
          console.error('Error fetching data:', err);
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [update]);

  return (
    <div className=" text-black flex justify-start flex-col w-full h-full overflow-hidden">
      <Title
        title={'Projects'}
        className={'text-xl flex relative items-start pb-8'}
      />
      <div className="mb-10 w-full px-8 flex justify-between items-center flex-wrap gap-4">
        <Button
          type="button"
          text="New Project"
          className="rounded-md px-8 "
          onClick={handleOpen}
        />
        <ModalBox
          mode={'create'}
          title={'Create Project'}
          open={open}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          inputs={inputs}
          register={register}
          control={control}
          errors={errors}
          loading={loading}
        />
        <ConfirmModal
          title={'Are you sure you want to delete project?'}
          handleClose={handleCloseDelete}
          open={openDelete}
          onSubmit={deleteProject}
          loading={loading}
          buttonText={'Delete'}
        />
        <div className="flex items-center gap-4">
          <Button
            type="button"
            text={`Sort by Date ${sortOrder === 'asc' ? '↑' : '↓'}`}
            className="rounded-md px-8 flex items-center gap-2"
            onClick={sortProjects}
          >
            {/* <FaSort /> */}
          </Button>
          {/* <Button
            type="button"
            text="Filters"
            className="rounded-md px-8 flex items-center gap-2"
            onClick={() => setShowFilters(!showFilters)}
          >
            <FaFilter />
          </Button> */}
        </div>
      </div>
      {/* 
      {showFilters && (
        <div className="mb-6 mr-8 px-8 py-2 bg-gray-100 rounded-md w-1/3 self-end">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
            <div>
              <label className="block mb-2">Start Date</label>
              <input
                type="date"
                value={filters.startDate}
                onChange={(e) =>
                  handleFilterChange('startDate', e.target.value)
                }
                className="w-full px-2 border rounded-md"
              />
            </div>
            <div>
              <label className="mb-2">End Date</label>
              <br />
              <input
                type="date"
                value={filters.endDate}
                onChange={(e) => handleFilterChange('endDate', e.target.value)}
                className="w-full px-2 border rounded-md"
              />
            </div>
            <div>
              <label className="block mb-2">Min Megawatt</label>
              <input
                type="number"
                value={filters.minMegawatt}
                onChange={(e) =>
                  handleFilterChange('minMegawatt', e.target.value)
                }
                className="w-full px-2 border rounded-md"
                placeholder="Min Megawatt"
              />
            </div>
            <div>
              <label className="block mb-2">Max Megawatt</label>
              <input
                type="number"
                value={filters.maxMegawatt}
                onChange={(e) =>
                  handleFilterChange('maxMegawatt', e.target.value)
                }
                className="w-full px-2 border rounded-md"
                placeholder="Max Megawatt"
              />
            </div>
          </div>
          <div className="mt-4 flex justify-end gap-4">
            <Button
              type="button"
              text="Reset"
              className="bg-gray-300 text-gray-800 rounded-md px-4 py-2"
              onClick={resetFilters}
            />
            <Button
              type="button"
              text="Apply Filters"
              className="bg-blue-500 text-white rounded-md px-4 py-2"
              onClick={applyFilters}
            />
          </div>
        </div>
      )} */}

      <div className="grid sm:grid-cols-2  lg:grid-cols-3 xl:grid-cols-5 gap-4 pb-4 px-8">
        {loading
          ? Array(20)
              .fill()
              .map((_, index) => <SkeletonCard key={index} />)
          : filteredProjects?.map((data) => {
              return (
                <div className="relative group/card">
                  <button
                    className="absolute top-2 right-2  justify-between hidden group-hover/card:block items-center gap-3 px-2 py-1 rounded-md group cursor-pointer text-xl"
                    onClick={() => handleCloseDelete(data?.id)}
                  >
                    <Delete
                      width="15px"
                      height="15px"
                      fill="#ffff"
                      className={'group-hover:fill-red-500 '}
                    />
                  </button>
                  <Link
                    to={`/admin/project/${data?.id}`}
                    key={data?.id}
                    style={{ textDecoration: 'none' }}
                  >
                    <tr
                      className="flex flex-col justify-between items-center rounded-xl text-white text-ellipsis overflow-hidden text-center h-40 w-full p-4"
                      style={{
                        border: '2px solid #0000',
                        borderBottom: 'none',
                        background:
                          'linear-gradient(#222239,#352B53) padding-box, linear-gradient(to right, #FF6B00 0%, #DD0077 55%, #7000FF 100%) border-box',
                      }}
                    >
                      <td>
                        <h1 className="text-base pt-2 max-w-[96%]">
                          {data?.name}
                        </h1>
                      </td>

                      <td className="text-white flex justify-start h-40 w-full items-end">
                        <h4 className="text-gray-300 text-xs  bottom-0 right-0">
                          Created at:{' '}
                          {moment(data.createdat).format('DD/MM/YYYY')}
                          <br />
                          Last update:{' '}
                          {moment(data.updatedat).format('DD/MM/YYYY')}
                        </h4>
                      </td>
                    </tr>
                  </Link>
                </div>
              );
            })}
      </div>
      {!loading && filteredProjects.length === 0 && (
        <div className="text-center text-gray-600 mt-8">
          <p>No projects found.</p>
          {/* <Button
            type="button"
            text="Show All Projects"
            className="mt-4 bg-blue-500 text-white rounded-md px-4 py-2"
            onClick={resetFilters}
          /> */}
        </div>
      )}
    </div>
  );
};

export default Main;
