import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../axiosInstance';

export const fetchData = createAsyncThunk('data/status', async () => {
  try {
    const response = await axiosInstance.getData('/clients');
    if (response) {
      return response.clients;
    }
    return [];
  } catch (error) {
    throw error;
  }
});

export const addClient = createAsyncThunk(
  'clientAdd/status',
  async (client) => {
    try {
      const response = await axiosInstance.postData(`/clients`, client);
      if (response && response.createdClient) {
        return response.createdClient;
      } else {
        throw new Error(response.response.data.message);
      }
    } catch (error) {
      throw error;
    }
  },
);

export const updateClient = createAsyncThunk(
  'clientEdit/status',
  async (client) => {
    try {
      const response = await axiosInstance.updateData(
        `/clients/${client.id}`,
        client,
      );
      if (response) {
        return response.updatedClient;
      }
      return;
    } catch (error) {
      throw error;
    }
  },
);

export const deleteClient = createAsyncThunk(
  'clientDelete/status',
  async (id) => {
    try {
      const response = await axiosInstance.deleteData(`/clients/${id}`);
      if (response) {
        return id;
      }
      return;
    } catch (error) {
      throw error;
    }
  },
);

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

const clientsDataSlice = createSlice({
  name: 'clients',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = 'succeeded';
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message;
      })

      .addCase(addClient.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addClient.fulfilled, (state, action) => {
        if (state.data.length === 0) {
          if (action.payload) {
            state.data = [action.payload];
          } else {
            state.status = 'error';
          }
        } else {
          state.data = [...state.data, action.payload];
        }
        state.status = 'succeeded';
      })
      .addCase(addClient.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message;
      })

      .addCase(updateClient.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateClient.fulfilled, (state, action) => {
        if (!action.payload) {
          state.status = 'error';
        } else {
          const clientIndex = state.data.findIndex(
            (row) => row.id === action.payload.id,
          );
          state.data[clientIndex].firstName = action.payload.firstName;
          state.data[clientIndex].lastName = action.payload.lastName;
          state.data[clientIndex].email = action.payload.email;
          state.data[clientIndex].description = action.payload.description;
          state.data[clientIndex].website = action.payload.website;
          state.data[clientIndex].is_active = action.payload.is_active;
          state.status = 'succeeded';
        }
      })
      .addCase(updateClient.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message;
      })

      .addCase(deleteClient.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteClient.fulfilled, (state, action) => {
        if (!action.payload) {
          state.status = 'error';
        } else {
          state.data = state.data.filter((row) => row.id !== action.payload);
          state.status = 'succeeded';
        }
      })
      .addCase(deleteClient.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message;
      });
  },
});

export default clientsDataSlice.reducer;
