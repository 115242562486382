import React, { useEffect, useState } from 'react';
import Textarea from './formComponents/Textarea';
import Input from './formComponents/Input';
import Button from './formComponents/Button';
import ModalLayout from './ModalLayout';
import FileUpload from './FileUpload';

const ModalBox = ({
  mode,
  data,
  title,
  open,
  handleClose,
  handleSubmit,
  onSubmit,
  inputs,
  register,
  handleFileChange,
  control,
  errors,
  loading,
}) => {
  const [inputData, setInputData] = useState({
    name: '',
    description: '',
    megawatt: '',
  });

  useEffect(() => {
    if (mode === 'edit' && data) {
      setInputData(data);
    }
  }, [mode, data]);

  return (
    <ModalLayout open={open} handleClose={handleClose}>
      <div>
        <h1 className="text-3xl mb-6">{title}</h1>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3">
          {inputs?.map((input, index) => {
            if (input.type === 'textarea') {
              return (
                <Textarea
                  key={index}
                  id={input.id}
                  placeholder={input.placeholder}
                  {...register(input.id, input.options)}
                  control={control}
                  errors={errors}
                  defaultValue={inputData[input.id]}
                  options={input.options}
                />
              );
            }
            if (mode === 'edit' && input.name === 'Location') {
              return null;
            } else if (input?.type === 'file') {
              return (
                <FileUpload
                  file={data}
                  handleFileChange={handleFileChange}
                  loading={loading}
                  register={register}
                  errors={errors}
                />
              );
            }
            return (
              <Input
                key={input.index}
                name={input.name}
                id={input.id}
                type={input.type}
                steps={input.steps}
                placeholder={input.placeholder}
                register={register}
                min={input.min}
                errors={errors}
                options={input.options}
                className="bg-primary "
                title={input.title}
                svg={input.svg}
                value={inputData[input.id]}
              />
            );
          })}

          <Button
            type="submit"
            text={title}
            className="rounded-md mt-3"
            disabled={loading}
          />
        </form>
      </div>
    </ModalLayout>
  );
};

export default ModalBox;
