import React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';

export default function PieGraph({ statistics }) {
  const data = [
    {
      id: 0,
      value: statistics.processing,
      label: 'In Process',
      color: '#dd6b20',
    },
    {
      id: 1,
      value: statistics.healthyPanels,
      label: 'Healthy',
      color: '#17a34a',
    },
    {
      id: 2,
      value: statistics.unhealthyPanels,
      label: 'Not Healthy',
      color: '#DC2626',
    },
  ];
  return (
    <PieChart
      style={{ transform: 'translateX(-50px)' }}
      series={[
        {
          data,
          highlightScope: { faded: 'global', highlighted: 'item' },
          faded: { innerRadius: 20, additionalRadius: -20, color: 'gray' },
          innerRadius: 20,
          outerRadius: 50,
          paddingAngle: 10,
          cornerRadius: 5,
          startAngle: -149,
          //   cx: 100, Position the Chart
          //   cy: 100, Position the Chart
        },
      ]}
      height={150}
      width={350}
      slotProps={{
        legend: {
          position: {
            vertical: 'middle',
            horizontal: 'right',
          },
          labelStyle: {
            fill: 'white',
          },
          itemMarkWidth: 20,
          itemMarkHeight: 5,
          markGap: 10,
          itemGap: 5,
          direction: 'column',
        },
      }}
    />
  );
}
