import { createSlice } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';

const storedToken = localStorage.getItem('token');

const checkTokenExpiration = () => {
  const tokenExpiration = storedToken
    ? new Date(jwt_decode(storedToken).exp * 1000) - new Date()
    : 0;
  return tokenExpiration;
};

const initialState = {
  isAuthorized: storedToken && checkTokenExpiration() > 0 ? true : false,
};

const authorizationSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    setIsAuthorized: (state, action) => {
      state.isAuthorized = action.payload;
    },
  },
});

export const { setIsAuthorized } = authorizationSlice.actions;
export default authorizationSlice.reducer;
