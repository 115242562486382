export default function Vector({ fillColor, width = '238', height = '592' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 238 592"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ backgroundColor: fillColor, zIndex: 2000 }}
    >
      <path
        d="M92.002 591.883C43.6458 573.744 10.1111 551.272 -7.69124 525.108C-22.9341 502.687 -26.4792 478.012 -18.2299 451.779C-10.5187 427.259 7.81004 401.913 33.386 380.414C56.0533 361.358 82.5305 347.049 104.202 342.147C124.758 337.49 138.562 341.703 143.055 354.014C147.27 365.504 155.403 369.977 166.578 366.951C178.593 363.695 193.556 351.578 206.655 334.5C220.389 316.583 230.612 295.179 234.699 275.779C240.028 250.469 239.628 206.163 177.073 193.769C113.075 181.08 112.199 141.574 122.745 110.678C130.604 87.6248 148.055 60.5489 170.613 36.3965C189.847 15.8074 209.496 1.14402 218.379 0.750313L218.296 1.12849C209.525 1.52486 190.033 16.1123 170.896 36.6024C148.383 60.7051 130.975 87.7096 123.132 110.694C112.645 141.451 113.523 180.768 177.243 193.378C202.28 198.346 219.764 208.975 229.198 224.966C237.192 238.512 239.227 256.068 235.085 275.734C230.972 295.209 220.733 316.671 206.933 334.659C193.589 352.075 178.87 363.974 166.561 367.309C155.177 370.391 146.904 365.858 142.627 354.203C138.152 342.077 124.508 337.912 104.146 342.522C82.5557 347.404 56.1706 361.668 33.5527 380.671C8.06964 402.097 -10.1919 427.354 -17.902 451.805C-26.1153 477.927 -22.5805 502.507 -7.39169 524.854C10.3639 550.985 43.8566 573.42 92.1517 591.536L92.002 591.883Z"
        fill="url(#paint0_linear_108_1149)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_108_1149"
          x1="215.328"
          y1="4.35478"
          x2="194.001"
          y2="612.928"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6B00" stopOpacity="0" />
          <stop offset="0.285521" stopColor="#FF6B00" />
          <stop offset="0.654905" stopColor="#7601F9" />
          <stop offset="0.833093" stopColor="#FF6B00" />
          <stop offset="1" stopColor="#FF6B00" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
