import { configureStore } from '@reduxjs/toolkit';
import expirationReducer from '../features/tokenExpiration/expirationSlice';
import clientsReducer from '../features/clients/clientsDataSlice';
import isAuthorizedReducer from '../features/authorization/authorizationSlice';

export const store = configureStore({
  reducer: {
    expirationReducer,
    clientsReducer,
    isAuthorizedReducer,
  },
});
